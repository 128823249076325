import React, { useEffect, useState } from 'react'
import { WhiteTooltip } from '../../../../Components/tooltip/TooltipComponent'
import { User } from '../../../../models/user'
import {
  getCompanyDetails,
  getDetailedUsers,
  removeUser,
  transferCases,
  updateUser,
} from '../../../../services/requests'
import LoadingCasesPlaceHolder from '../../../myCases_myScenarios/myCasesComponents/LoadingCasesPlaceHolder'
import SearchBar from '../../../myCases_myScenarios/myCasesComponents/SearchBar'
import UsersListTitles from './components/UsersListTitles'
import actionImg from '../../../../resources/images/190-actionsUser.svg'
import SettingPopUp from '../components/SettingPopUp'
import UserDetailsPopUp from './components/UserDetailsPopUp'
import Button from '../../../../Components/Buttons/Button/Button'
import plus from '../../../../resources/images/003-plusSignThinWhite.svg'
import CreateUser from './components/CreateUser'
import {
  SortDirection,
  SubscriptionPlan,
  UsersSortOption,
} from '../../../../models/generalTypes'
import {
  deepCloneObject,
  findHandlingErrorState,
  logActivity,
} from '../../../../services/commonFunctions'
import { useRecoilState, useRecoilValue } from 'recoil'
import { userState } from '../../../../states/UserState'
import { handlingErrorsState } from '../../../../states/HandlingErrorsState'
import OnlyOwnerPopUp from '../components/OnlyOwnerPopUp'
import { UserRole, UserRoleKeys } from '../../../../models/enums'
import { getText } from '../../../../services/textFunctions'
import useWindowSize from '../../../../customHooks/useWindowSize'

const Users = () => {
  const user = useRecoilValue(userState)
  const [handlingErrors, setHandlingErrors] =
    useRecoilState(handlingErrorsState)
  const { width } = useWindowSize()

  const [loading, setLoading] = useState(true)
  const [loadingPopUp, setLoadingPopUp] = useState(false)
  const [widthOfCaseContainer, setWidthOfCaseConainer] = useState<
    number | undefined
  >(
    document.getElementById('caseContainer-users')
      ? document.getElementById('caseContainer-users')!.offsetWidth
      : undefined,
  )
  const [resize, setResize] = useState(false)
  const [selectedUser, setSelectedUser] = useState<User | undefined>(undefined)
  const [usersList, setUsersList] = useState<User[] | undefined>(undefined)
  const [showedList, setShowedList] = useState<User[] | undefined>(undefined)
  const [subscriptionPlan, setSubscriptionPlan] = useState<
    SubscriptionPlan | undefined
  >(undefined)
  const [newUserPopUp, setNewUserPopUp] = useState(false)
  const [hoverElement, setHoverElement] = useState('')
  const [searchKey, setSearchKey] = useState('')
  const [sortOption, setSortOption] = useState<UsersSortOption>('name')
  const [sortDirection, setSortDirection] = useState<SortDirection>('up')
  const [onlyOwnerDataDelete, setOnlyOwnerDataDelete] = useState<any>(undefined)
  const [onlyOwnerDataUpdate, setOnlyOwnerDataUpdate] = useState<any>(undefined)
  const [successDelete, setSuccessDelete] = useState(false)
  const [showUpdateButton, setShowUpdateButton] = useState(false)
  const [showSavedUpdated, setShowSavedUpdated] = useState(false)
  const [tempUpdateBody, setTempUpdateBody] = useState<
    | {
        username: string
        first_name?: string
        last_name?: string
        role?: string
      }
    | undefined
  >(undefined)

  useEffect(() => {
    document.addEventListener('mousedown', onMouseDown, false)
    return () => {
      document.removeEventListener('mousedown', onMouseDown, false)
    }
    // eslint-disable-next-line
  }, [hoverElement])

  const onMouseDown = (e: any) => {
    setHoverElement('')
  }

  useEffect(() => {
    setTimeout(() => {
      getDetailedUsers().then((res) => {
        setHandlingErrors(
          findHandlingErrorState(res, handlingErrors, 'getDetailedUsers', true),
        )

        if (!('errorCode' in res)) {
          const allUsers = res.data.map((userJson: any) =>
            User.UserFromDB(userJson),
          )
          setUsersList([...allUsers])
          setShowedList([...allUsers])

          getCompanyDetails().then((res2) => {
            setHandlingErrors(
              findHandlingErrorState(
                res2,
                handlingErrors,
                'getCompanyDetails',
                true,
              ),
            )

            if (!('errorCode' in res2)) {
              setSubscriptionPlan({
                name: res2.data.subscription_plan.name,
                expiration_date: res2.data.subscription_plan.expiration_date,
                number_of_users: res2.data.subscription_plan.number_of_users,
                allowed_number_of_users:
                  res2.data.subscription_plan.allowed_number_of_users,
                number_of_spectators:
                  res2.data.subscription_plan.number_of_spectators,
                allowed_number_of_spectators:
                  res2.data.subscription_plan.allowed_number_of_spectators,
                features: [...res2.data.subscription_plan.features],
              })
            }

            setLoading(false)
            setResize(true)
          })
        }
      })
    }, 500)
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    getCompanyDetails().then((res) => {
      if (!('errorCode' in res)) {
        setSubscriptionPlan({
          name: res.data.subscription_plan.name,
          expiration_date: res.data.subscription_plan.expiration_date,
          number_of_users: res.data.subscription_plan.number_of_users,
          allowed_number_of_users:
            res.data.subscription_plan.allowed_number_of_users,
          number_of_spectators: res.data.subscription_plan.number_of_spectators,
          allowed_number_of_spectators:
            res.data.subscription_plan.allowed_number_of_spectators,
          features: [...res.data.subscription_plan.features],
        })
      }
    })

    if (usersList) {
      updateSortSearch()
    }

    // eslint-disable-next-line
  }, [usersList])

  useEffect(() => {
    if (usersList) {
      updateSortSearch()
    }

    // eslint-disable-next-line
  }, [sortOption, sortDirection, searchKey])

  useEffect(() => {
    window.addEventListener('resize', findWidthOfCaseContainer)
    if (resize) {
      findWidthOfCaseContainer()
      setResize(false)
    }

    return () => {
      window.removeEventListener('resize', findWidthOfCaseContainer)
    }
    // eslint-disable-next-line
  }, [resize])

  const findWidthOfCaseContainer = () => {
    if (document.getElementById('caseContainer-users')) {
      setWidthOfCaseConainer(
        document.getElementById('caseContainer-users')!.offsetWidth,
      )
    }
  }

  const changeUser = (newUserData?: any) => {
    if (usersList) {
      let newList = [...usersList]
      const index = newList.findIndex((user) =>
        newUserData
          ? user.email === newUserData.username
          : user.email === selectedUser!.email,
      )

      let updatedUser = newUserData
        ? User.UserFromDB(newUserData)
        : deepCloneObject(selectedUser)
      if (!newUserData) {
        updatedUser.tfaType = undefined
      }

      newList[index] = deepCloneObject(updatedUser)
      setUsersList([...newList])
      setSelectedUser(deepCloneObject(updatedUser))
    }
  }

  const addUser = (newUserData: any) => {
    if (usersList) {
      const newUser = User.UserFromDB(newUserData)
      let newList = [...usersList, newUser]
      setUsersList([...newList])
      setTimeout(() => {
        setNewUserPopUp(false)
      }, 2500)
    }
  }

  const removeUserFromList = () => {
    if (usersList && selectedUser) {
      const newList = usersList.filter(
        (user) => user.email !== selectedUser.email,
      )
      setUsersList([...newList])
    }
  }

  const onDeleteAccount = (force?: boolean) => {
    setLoadingPopUp(true)
    removeUser({
      username: selectedUser!.email,
      force_delete: force,
    }).then((res) => {
      if (!('errorCode' in res)) {
        setSuccessDelete(true)
        removeUserFromList()

        setTimeout(() => {
          setSuccessDelete(false)
          setSelectedUser(undefined)
          setOnlyOwnerDataDelete(undefined)
        }, 2500)

        //Mixpanel 110
        logActivity(
          false,
          "Deleted other user's account in Delete User Account of User Details pop up",
        )
      } else {
        if (
          res.errorCode === 403 &&
          res.message.error &&
          res.message.error.includes('only owner')
        ) {
          setOnlyOwnerDataDelete(res.message)
        } else {
          setHandlingErrors(
            findHandlingErrorState(res, handlingErrors, 'removeUser'),
          )
        }
      }

      setLoadingPopUp(false)
    })
  }

  const onUpdateUser = (body: {
    username: string
    first_name?: string
    last_name?: string
    role?: string
  }) => {
    setLoadingPopUp(true)

    updateUser({ ...body }).then((res) => {
      if (!('errorCode' in res)) {
        changeUser(res.data)
        setShowSavedUpdated(true)
        setShowUpdateButton(false)
        setOnlyOwnerDataUpdate(undefined)
        setTempUpdateBody(undefined)

        setTimeout(() => {
          setShowSavedUpdated(false)
        }, 2500)

        //Mixpanel 106
        logActivity(
          false,
          "Changed user's first name or last name or user role in User Details pop up",
          {
            previousRole: selectedUser?.role,
            currentRole: body.role
              ? UserRole[body.role as UserRoleKeys]
              : selectedUser?.role,
          },
        )
      } else {
        if (
          res.errorCode === 403 &&
          res.message.error &&
          res.message.error.includes('only owner')
        ) {
          setTempUpdateBody({ ...body })
          setOnlyOwnerDataUpdate(res.message)
        } else {
          setHandlingErrors(
            findHandlingErrorState(res, handlingErrors, 'updateUser'),
          )
        }
      }

      setLoadingPopUp(false)
    })
  }

  const onTransferCases = async (usernames: string[], forUpdate?: boolean) => {
    setLoadingPopUp(true)
    let body:
      | {
          case_to_user: {
            case: string
            user: string
          }[]
        }
      | undefined = undefined
    if (usernames.length > 0) {
      body = {
        case_to_user: usernames
          .map((username, index) => ({
            user: username,
            case: (forUpdate ? onlyOwnerDataUpdate : onlyOwnerDataDelete)
              .groups[index].groupid as string,
          }))
          .filter((ctu) => ctu.user !== 'ADMIN'),
      }
    }

    if (body && body.case_to_user.length > 0) {
      transferCases(body).then((res) => {
        setHandlingErrors(
          findHandlingErrorState(res, handlingErrors, 'transferCases'),
        )

        if (!('errorCode' in res)) {
          setOnlyOwnerDataDelete(undefined)
          setOnlyOwnerDataUpdate(undefined)
          if (forUpdate) {
            onUpdateUser({ ...tempUpdateBody! })
          } else {
            onDeleteAccount(true)
          }
        } else {
          setLoadingPopUp(false)
        }
      })
    } else {
      onDeleteAccount(true)
    }
  }

  const searchFor = (e: React.ChangeEvent<HTMLInputElement>) => {
    const key = e.target?.value.trim().toLowerCase()
    setSearchKey(key)
  }

  const handleSearch = () => {
    return usersList?.filter(
      (user) =>
        user.fullName().toLowerCase().includes(searchKey) ||
        user.email.toLowerCase().includes(searchKey),
    )
  }

  const sortBy = (option: UsersSortOption, listToSort?: User[]) => {
    const comparator = (a: string, b: string, direction: SortDirection) => {
      if (a.toLowerCase() > b.toLowerCase()) return direction === 'up' ? 1 : -1
      if (a.toLowerCase() < b.toLowerCase()) return direction === 'up' ? -1 : 1
      return 0
    }

    let tempList = listToSort ? [...listToSort] : [...showedList!]

    tempList = tempList.sort((a: User, b: User) => {
      switch (option) {
        case 'name':
          return comparator(a.fullName(), b.fullName(), sortDirection)
        case 'role':
          if (a.role === b.role) {
            return comparator(a.fullName(), b.fullName(), sortDirection)
          }
          return comparator(a.role, b.role, sortDirection)
        case 'state':
          const resA =
            a.hasRegistered === 'Yes' ? 'Registered' : 'Registration Pending'
          const resB =
            b.hasRegistered === 'Yes' ? 'Registered' : 'Registration Pending'

          return comparator(resA, resB, sortDirection)
        default:
          return comparator(a.fullName(), b.fullName(), sortDirection)
      }
    })

    return tempList
  }

  const updateSortSearch = () => {
    let tempList = handleSearch()
    tempList = sortBy(sortOption, tempList)

    setShowedList([...tempList])
  }

  const onClickTitle = (option: UsersSortOption) => {
    if (sortOption === option) {
      setSortDirection((prevState) => (prevState === 'up' ? 'down' : 'up'))
    } else {
      setSortDirection('up')
    }
    setSortOption(option)
  }

  const onExit = () => {
    if (!loadingPopUp) {
      setSelectedUser(undefined)
      setOnlyOwnerDataDelete(undefined)
      setOnlyOwnerDataUpdate(undefined)
      setShowUpdateButton(false)
      setShowSavedUpdated(false)
      setTempUpdateBody(undefined)
    }
  }

  return (
    <div className="tool-component for-users" id="usersDetailsComp">
      <h2
        className="tool-component-title"
        id="accountDetails-title"
        data-textattribute="title-188"
      >
        {getText('title-188', user.settings)}
      </h2>
      {loading ? (
        <LoadingCasesPlaceHolder forSettings />
      ) : usersList && subscriptionPlan ? (
        <>
          <div className="myCasesBox for-settings">
            <div className="myCasesBox-content-container for-users">
              <div className="myCasesBox-searchBar">
                <SearchBar
                  itemName={'User'}
                  searchFor={searchFor}
                  isSettingsPage
                />
              </div>
              <UsersListTitles
                sortOption={sortOption}
                sortDirection={sortDirection}
                widthOfCaseContainer={widthOfCaseContainer}
                onClick={onClickTitle}
              />
              <div
                className="myCasesListContainer for-users"
                data-openreplay-obscured
              >
                {showedList?.map((userFromList, index) => (
                  <div
                    key={`caseContainer${index}`}
                    className="caseContainer"
                    id="caseContainer-users"
                  >
                    <div>
                      <div
                        className={`${
                          width < 836
                            ? 'caseTitleUserList userlist'
                            : 'caseTitle userlist'
                        } ${
                          userFromList.email === user.email ? 'nonActive' : ''
                        }`}
                        onMouseEnter={() => {
                          if (userFromList.email !== user.email) {
                            document.getElementById(
                              `caseTitle${index}`,
                            )!.style.textDecoration = 'underline'
                          }
                        }}
                        onMouseLeave={() => {
                          document.getElementById(
                            `caseTitle${index}`,
                          )!.style.textDecoration = ''
                        }}
                        style={width < 836 ? { width: '309px' } : {}}
                        onClick={
                          userFromList.email !== user.email
                            ? () => {
                                setSelectedUser(userFromList)
                                //Mixpanel 105
                                logActivity(
                                  false,
                                  'Opened User Details pop up in Users',
                                )
                              }
                            : undefined
                        }
                      >
                        <p
                          className={`caseTitleText ${
                            userFromList.email === user.email ? 'nonActive' : ''
                          }`}
                          id={`caseTitle${index}`}
                        >
                          {userFromList.fullName().trim() === ''
                            ? '-'
                            : userFromList.fullName()}
                        </p>
                        <p
                          className={`caseTitleEmail ${
                            userFromList.email === user.email ? 'nonActive' : ''
                          }`}
                          id={`caseTitleEmail${index}`}
                        >
                          {userFromList.email}
                        </p>
                      </div>
                    </div>
                    <div className="caseLeftPart">
                      <div className="caseDetailsContainer caseDetailsContainer-small ">
                        <p className="caseDetail">{userFromList.role}</p>
                      </div>
                      <div className="caseDetailsContainer caseDetailsContainer-small">
                        <p
                          className="caseDetail"
                          id={`registrationStatus${index}`}
                          data-textattribute={
                            userFromList.hasRegistered === 'Yes'
                              ? 'description-133'
                              : 'description-134'
                          }
                        >
                          {userFromList.hasRegistered === 'Yes'
                            ? getText('description-133', user.settings)
                            : getText('description-134', user.settings)}
                        </p>
                      </div>
                      <div className="caseMenuContainer  caseDetailsContainer-small">
                        <div className="caseMenuSubContainer only-one">
                          {userFromList.email !== user.email && (
                            <WhiteTooltip
                              title={getText('tooltip-20', user.settings)}
                              textAttribute={'tooltip-20'}
                              id="userDetailsTooltip"
                            >
                              <img
                                src={actionImg}
                                alt="actionImg"
                                id={`actionImg${index}`}
                                onClick={() => {
                                  setSelectedUser(userFromList)
                                  //Mixpanel 105
                                  logActivity(
                                    false,
                                    'Opened User Details pop up in Users',
                                  )
                                }}
                              />
                            </WhiteTooltip>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="addNewCaseContainer">
                <div className="createCaseContainer">
                  <Button
                    id={'addNewUserButton'}
                    buttonText={getText('button-96', user.settings)}
                    buttonTextAttribute={'button-96'}
                    buttonType="contained"
                    type="button"
                    icon={plus}
                    className="addNewCaseButton"
                    onClick={() => {
                      setNewUserPopUp(true)
                      //Mixpanel 103
                      logActivity(false, 'Pressed "ADD NEW USER" in Users')
                    }}
                    side="left"
                    NoUpperCase={true}
                    small={false}
                  />
                  <div className="descriptionTextContainer">
                    <p
                      id="accountsInUseText"
                      data-textattribute="description-135"
                    >
                      {`${subscriptionPlan?.number_of_users.toString()}/${subscriptionPlan?.allowed_number_of_users.toString()} ${getText(
                        'description-135',
                        user.settings,
                      )}`}
                    </p>
                    {subscriptionPlan &&
                      subscriptionPlan.allowed_number_of_spectators > 0 && (
                        <p
                          id="viewersInUseText"
                          data-textattribute="description-136"
                        >
                          {`${subscriptionPlan.number_of_spectators.toString()}/${subscriptionPlan.allowed_number_of_spectators.toString()} ${getText(
                            'description-136',
                            user.settings,
                          )}`}
                        </p>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {selectedUser && (
            <SettingPopUp
              big={!onlyOwnerDataDelete && !onlyOwnerDataUpdate}
              onClose={onExit}
              noAbsolute={!onlyOwnerDataDelete && !onlyOwnerDataUpdate}
              onlyOwner={onlyOwnerDataDelete || onlyOwnerDataUpdate}
              smallOwner={
                (onlyOwnerDataDelete || onlyOwnerDataUpdate) &&
                user.role === UserRole.COMPANY_ADMIN
              }
            >
              {onlyOwnerDataDelete || onlyOwnerDataUpdate ? (
                <OnlyOwnerPopUp
                  data={onlyOwnerDataDelete ?? onlyOwnerDataUpdate}
                  onTransferCases={onTransferCases}
                  loading={loadingPopUp}
                  onClose={onExit}
                  forOtherUser={onlyOwnerDataDelete}
                  forChangeRole={onlyOwnerDataUpdate}
                />
              ) : (
                <UserDetailsPopUp
                  changeUser={changeUser}
                  loading={loadingPopUp}
                  success={successDelete}
                  setLoading={setLoadingPopUp}
                  user={selectedUser}
                  hoverElement={hoverElement}
                  setHoverElement={setHoverElement}
                  onClose={onExit}
                  onDeleteAccount={() => onDeleteAccount()}
                  onUpdateUser={onUpdateUser}
                  showSaved={showSavedUpdated}
                  setShowUpdateButton={setShowUpdateButton}
                  showUpdateButton={showUpdateButton}
                  subscriptionPlan={subscriptionPlan}
                />
              )}
            </SettingPopUp>
          )}
          {newUserPopUp && (
            <SettingPopUp
              onClose={() => {
                if (!loadingPopUp) {
                  setNewUserPopUp(false)
                }
              }}
              bigForm
            >
              <CreateUser
                loading={loadingPopUp}
                setLoading={setLoadingPopUp}
                hoverElement={hoverElement}
                setHoverElement={setHoverElement}
                addUser={addUser}
                subscriptionPlan={subscriptionPlan!}
              />
            </SettingPopUp>
          )}
        </>
      ) : null}
    </div>
  )
}

export default Users
