import { createRef, useEffect, useState } from 'react'
import emptyImg from '../../../../../../../../resources/images/209-empty.svg'
import Draggable from 'react-draggable'
import HandleComponent from './HandleComponent'
import { useRecoilState, useRecoilValue } from 'recoil'
import { userState } from '../../../../../../../../states/UserState'
import { stringAndRoundBigNumbers } from '../../../../../../../../Modules/DisputeModules/AppFunctionsNew'
import { resultsSettingsState } from '../../../../../../../../states/ResultsSettingsState'
import {
  deepCloneObject,
  logActivity,
} from '../../../../../../../../services/commonFunctions'
import { scenarioIdentityState } from '../../../../../../../../states/ScenarioIdentityState'
import { saveResultsSettings } from '../../../../../../../../services/requests'
import {
  getDistanceNumberForSelectionGraph,
  getHighestValue,
  getLowestValue,
} from '../../../../../../../../services/resultsFunctions'
import { freemiumState } from '../../../../../../../../states/FreemiumState'
import { ResultsSettings } from '../../../../../../../../models/generalTypes'
interface Props {
  targetElement: HTMLElement | null
  graphData: number[][]
  statisticalModel: boolean
  fullGraphWidth: number
  handlesXpercentages: number[]
  setHandlesXpercentages: (handlesXpercentages: number[]) => void
  tree?: boolean
  negativeGraph: boolean
  treeTableColumnsState?:
    | 'onlyAmount'
    | 'onlyInterest'
    | 'onlyExternal'
    | 'externalInterest'
    | undefined
  graphScale: number
  loadingResultsSettings?: boolean
  firstTimeLoad: boolean
  pdfPreview?: boolean
  doubleLimitValues: boolean
  resultsViewParty?: 'client' | 'opposing'
}

export default function SelectionArea(props: Props) {
  const allowedDistance: number = props.tree ? 0.04 : 0.03

  const user = useRecoilValue(userState)
  const freemium = useRecoilValue(freemiumState)
  const scenarioIdentity = useRecoilValue(scenarioIdentityState)
  const [resultsSettings, setResultsSettings] =
    useRecoilState(resultsSettingsState)
  const [showAddSelection, setShowAddSelection] = useState(false)
  const [showDeleteButton, setShowDeleteButton] = useState(false)
  const [hoverArea, setHoverArea] = useState<undefined | number>(undefined)

  const img = new Image()
  img.src = emptyImg
  const [areaStartX, setAreaStartX] = useState<number | undefined>(undefined)

  const [handleAmounts, setHandleAmounts] = useState<number[][]>([
    [0, 0],
    [0, 0],
  ])
  const [selectionAreasProbabilites, setSelectionAreasProbabilites] = useState<
    number[]
  >([20])
  const [draggingLine, setDraggingLine] = useState(false)
  const [topLeftOffset, setTopLeftOffset] = useState<number[]>([])
  const [selectionAreaHeight, setSelectionAreaHeight] = useState(
    props.targetElement?.getBoundingClientRect().height
      ? props.targetElement?.getBoundingClientRect().height * props.graphScale
      : 0,
  )

  useEffect(() => {
    if (
      props.targetElement &&
      props.targetElement?.getBoundingClientRect().height
    ) {
      setSelectionAreaHeight(
        props.targetElement?.getBoundingClientRect().height * props.graphScale,
      )
    }
  }, [props.targetElement, props.graphScale, props.pdfPreview])

  const [draggingFromAddButton, setDraggingFromAddButton] = useState(false)
  const [deleting, setDeleting] = useState(false)

  useEffect(() => {
    document.addEventListener('mouseup', onMouseUp)
    return () => {
      document.removeEventListener('mouseup', onMouseUp)
    }
    // eslint-disable-next-line
  }, [draggingFromAddButton, draggingLine])

  function onMouseUp(e: any) {
    if (areaStartX !== undefined) {
      setAreaStartX(undefined)
      setDraggingLine(false)
    }

    setDraggingFromAddButton(false)
  }

  //The selection Area is manually placed over the Victory Chart , so it has to be adjusted according to the graph width and height
  useEffect(() => {
    if (props.tree) {
      setTopLeftOffset(
        getDistanceNumberForSelectionGraph(
          'topLeftOffset',
          props.treeTableColumnsState!,
        ),
      )
    } else if (props.pdfPreview) {
      setTopLeftOffset(
        getDistanceNumberForSelectionGraph('topLeftOffset', 'report'),
      )
    } else {
      setTopLeftOffset(
        getDistanceNumberForSelectionGraph('topLeftOffset', 'results'),
      )
    }
    // eslint-disable-next-line
  }, [props.graphData, props.pdfPreview])

  useEffect(() => {
    if (props.fullGraphWidth > 0) {
      calculateHandleAmountsAndProbabilities(props.handlesXpercentages)
    }
    let shouldSave = false
    let tempResultsSettings: ResultsSettings = {
      settings: {
        roundedResults: true,
        selectionGraphSettings: {
          position: { client: [0.2, 0.4], opposing: [0.2, 0.4] },
          showRanges: true,
        },
      },
      scenarioId: resultsSettings.scenarioId,
    }

    if (
      !draggingLine &&
      !props.loadingResultsSettings &&
      !props.firstTimeLoad &&
      !props.tree
    ) {
      if (
        props.resultsViewParty &&
        resultsSettings.settings.selectionGraphSettings === undefined
      ) {
        shouldSave = true
      } else if (
        props.resultsViewParty &&
        resultsSettings.settings.selectionGraphSettings &&
        props.handlesXpercentages !==
          resultsSettings.settings.selectionGraphSettings.position[
            props.resultsViewParty
          ] &&
        !props.pdfPreview
      ) {
        tempResultsSettings = {
          settings: {
            ...resultsSettings.settings,
            selectionGraphSettings: {
              position: {
                ...resultsSettings.settings.selectionGraphSettings.position,
                [props.resultsViewParty]: props.handlesXpercentages,
              },
              showRanges:
                resultsSettings.settings.selectionGraphSettings.showRanges,
            },
          },
          scenarioId: resultsSettings.scenarioId,
        }
        shouldSave = true
      }
      if (shouldSave) {
        setResultsSettings(tempResultsSettings)

        if (!freemium.isFreemium) {
          saveResultsSettings(
            scenarioIdentity.caseId,
            scenarioIdentity.scenarioId,
            tempResultsSettings,
          )
        }
      }
    }
    // eslint-disable-next-line
  }, [
    props.handlesXpercentages,
    props.graphData,
    draggingLine,
    props.fullGraphWidth,
  ])

  function calculateHandleAmountsAndProbabilities(
    handlesXpercentages: number[],
  ) {
    let limits = new Array(handlesXpercentages.length).fill(0)

    for (let [handleIndex, handlePercentage] of handlesXpercentages.entries()) {
      for (let [
        amountProbabityIndex,
        amountProbability,
      ] of props.graphData.entries()) {
        if (amountProbability[1] >= handlePercentage) {
          if (handleIndex === 0) {
            let limit =
              props.graphData[
                amountProbabityIndex + 1 <= props.graphData.length - 1
                  ? amountProbabityIndex + 1
                  : props.graphData.length - 1
              ][0]
            limits[handleIndex] = [limit, limit]
          } else if (handleIndex === handlesXpercentages.length - 1) {
            limits[handleIndex] = [amountProbability[0], amountProbability[0]]
          } else {
            limits[handleIndex] = [
              amountProbability[0],
              props.graphData[
                amountProbabityIndex + 1 <= props.graphData.length - 1
                  ? amountProbabityIndex + 1
                  : props.graphData.length - 1
              ][0],
            ]
          }
          break
        }
      }
    }

    setHandleAmounts(limits)

    let tempProbabilities = new Array(handlesXpercentages.length - 1).fill(0)
    for (let i = 0; i < tempProbabilities.length; i++) {
      tempProbabilities[i] =
        (handlesXpercentages[i + 1] - handlesXpercentages[i]) * 100
    }

    setSelectionAreasProbabilites(tempProbabilities)
  }

  const handleDragLine = (e: any, handleIndex: number) => {
    if (
      draggingFromAddButton ||
      (e.target.className &&
        typeof e.target.className === 'string' &&
        e.target.className.includes('handleDelete'))
    ) {
      return
    }

    setShowAddSelection(false)
    setShowDeleteButton(false)
    e.preventDefault()
    let newhandlePositionPercentage = 0

    if (props.targetElement?.getBoundingClientRect()) {
      newhandlePositionPercentage =
        ((e.clientX - props.targetElement?.getBoundingClientRect().left) /
          props.fullGraphWidth) *
        props.graphScale
    }

    let tempHandlesXpercentages = deepCloneObject(props.handlesXpercentages)
    if (newhandlePositionPercentage < 0) {
      newhandlePositionPercentage = 0
    } else if (newhandlePositionPercentage > 1) {
      newhandlePositionPercentage = 1
    } else {
      if (props.handlesXpercentages[handleIndex + 1]) {
        if (
          newhandlePositionPercentage >
          props.handlesXpercentages[handleIndex + 1] - allowedDistance
        ) {
          newhandlePositionPercentage =
            props.handlesXpercentages[handleIndex + 1] - allowedDistance
        }
      }
      if (props.handlesXpercentages[handleIndex - 1] !== undefined) {
        if (
          newhandlePositionPercentage <
          props.handlesXpercentages[handleIndex - 1] + allowedDistance
        ) {
          newhandlePositionPercentage =
            props.handlesXpercentages[handleIndex - 1] + allowedDistance
        }
      }

      tempHandlesXpercentages[handleIndex] = newhandlePositionPercentage
    }

    if (
      e.target.className &&
      ((typeof e.target.className === 'string' &&
        (e.target.className.includes('homescreen') ||
          e.target.className.includes('tool-component') ||
          e.target.className.includes('tab-content'))) ||
        e.target.className.baseVal !== undefined)
    ) {
      if (handleIndex === props.handlesXpercentages.length - 1) {
        tempHandlesXpercentages[handleIndex] = 1
      } else if (handleIndex === 0) {
        tempHandlesXpercentages[handleIndex] = 0
      }
    }

    if (!draggingLine) {
      //Mixpanel 190 (All)
      logActivity(freemium.isFreemium, 'Changed a range in selection graph')
    }
    props.setHandlesXpercentages(tempHandlesXpercentages)
  }

  function addNewSelectionArea(place: 'left' | 'right') {
    if (
      props.handlesXpercentages.length < 2 ||
      props.handlesXpercentages.length > 9
    )
      return
    let tempHandlesXpercentages = deepCloneObject(props.handlesXpercentages)
    if (place === 'left') {
      tempHandlesXpercentages.unshift(props.handlesXpercentages[0] - 0.1)
    } else {
      tempHandlesXpercentages.push(
        props.handlesXpercentages[props.handlesXpercentages.length - 1] + 0.1,
      )
    }

    props.setHandlesXpercentages(tempHandlesXpercentages)
    //Mixpanel 191 (All)
    logActivity(freemium.isFreemium, 'Added a range in selection graph')
  }

  function deleteHandleLine(handleIndex: number) {
    setDeleting(true)

    if (props.handlesXpercentages.length <= 2) return
    let tempHandlesXposition = deepCloneObject(props.handlesXpercentages)
    tempHandlesXposition.splice(handleIndex, 1)
    calculateHandleAmountsAndProbabilities(tempHandlesXposition)
    props.setHandlesXpercentages(tempHandlesXposition)
    setShowDeleteButton(false)
    //Mixpanel 192 (All)
    logActivity(freemium.isFreemium, 'Deleted a range in selection graph')
  }

  useEffect(() => {
    if (deleting) {
      setTimeout(() => {
        setDeleting(false)
      }, 30)
    }
  }, [deleting])

  function snapToclosestRealProbability(handleIndex: number) {
    let snapppedAmountAfter = 0
    let snappedAmountBefore = 0
    for (let [index, amountProbability] of props.graphData.entries()) {
      if (amountProbability[1] >= props.handlesXpercentages[handleIndex]) {
        snapppedAmountAfter = amountProbability[0]
        if (index > 0) {
          snappedAmountBefore = props.graphData[index - 1][0]
        } else {
          snappedAmountBefore = 0
        }
        break
      }
    }

    let snappedPositionPercentageBefore = getLowestValue(
      props.graphData,
      snappedAmountBefore,
    )
    let snappedPositionPercentageAfter = getHighestValue(
      props.graphData,
      snapppedAmountAfter,
    )

    let tempHandlesXposition = deepCloneObject(props.handlesXpercentages)

    if (snappedPositionPercentageBefore === Infinity && handleIndex === 0) {
      tempHandlesXposition[handleIndex] = 0
    } else if (
      props.handlesXpercentages[handleIndex] - snappedPositionPercentageBefore <
      snappedPositionPercentageAfter - props.handlesXpercentages[handleIndex]
    ) {
      if (props.handlesXpercentages.includes(snappedPositionPercentageBefore)) {
        tempHandlesXposition[handleIndex] = snappedPositionPercentageAfter
      } else {
        tempHandlesXposition[handleIndex] = snappedPositionPercentageBefore
      }
      props.setHandlesXpercentages(tempHandlesXposition)
    } else {
      if (props.handlesXpercentages.includes(snappedPositionPercentageAfter)) {
        tempHandlesXposition[handleIndex] = snappedPositionPercentageBefore
      } else {
        tempHandlesXposition[handleIndex] = snappedPositionPercentageAfter
      }
      props.setHandlesXpercentages(tempHandlesXposition)
    }
  }

  return (
    <div
      className="selectionAreaContainer"
      id="selectionAreaContainer"
      style={{
        width: props.fullGraphWidth,
        height: selectionAreaHeight,
        top: topLeftOffset[0],
        left: topLeftOffset[1],
      }}
    >
      {!props.pdfPreview && (
        <div
          className="deleteButtonsBackground"
          style={{ width: props.fullGraphWidth, top: selectionAreaHeight }}
          onMouseEnter={() => {
            if (props.handlesXpercentages.length > 2) setShowDeleteButton(true)
          }}
          onMouseLeave={(e: any) => {
            if (
              (e.relatedTarget.className &&
                e.relatedTarget.className.length > 0 &&
                !e.relatedTarget.className.includes('handleDelete')) ||
              typeof e.relatedTarget.className !== 'string'
            ) {
              setShowDeleteButton(false)
            }
          }}
        ></div>
      )}
      {selectionAreasProbabilites.map((item, index) => {
        return (
          <div
            key={`selectionArea${index}`}
            id={`selectionArea-${index}${props.pdfPreview ? '-preview' : ''}`}
            className={`selectionArea ${
              areaStartX !== undefined ? 'dragging' : ''
            }`}
            style={{
              width:
                Math.abs(
                  props.handlesXpercentages[index + 1] -
                    props.handlesXpercentages[index],
                ) * props.fullGraphWidth
                  ? Math.abs(
                      props.handlesXpercentages[index + 1] -
                        props.handlesXpercentages[index],
                    ) * props.fullGraphWidth
                  : 0,
              height: selectionAreaHeight,
              left: props.handlesXpercentages[index] * props.fullGraphWidth,
            }}
            onMouseEnter={() => {
              setShowAddSelection(true)
              if (props.handlesXpercentages.length > 2)
                setShowDeleteButton(true)
              setHoverArea(index)
            }}
            onMouseLeave={() => {
              setShowAddSelection(false)
              setShowDeleteButton(false)
              setHoverArea(undefined)
            }}
          >
            <div
              className={`selectionArea-probabilityContainer ${
                props.tree ? 'tree' : ''
              } ${props.negativeGraph ? 'negativeGraph' : ''}`}
            >
              <p
                className={`selectionAreaProbabilityText robotoNumbers ${
                  props.tree ? 'tree' : ''
                } ${selectionAreasProbabilites[index] < 5 ? 'small' : ''} ${
                  hoverArea === index
                    ? 'highlight'
                    : hoverArea !== undefined
                    ? 'notHighlight'
                    : ''
                }`}
                id={`selectionAreaProbabilityText-${index}${
                  props.pdfPreview ? '-preview' : ''
                }`}
              >
                {stringAndRoundBigNumbers(
                  selectionAreasProbabilites[index],
                  user.settings,
                  resultsSettings.settings.roundedResults && !props.tree,
                  0,
                  true,
                )}
                %
              </p>
            </div>
          </div>
        )
      })}
      {props.handlesXpercentages.map((item, index) => {
        const handleRef = createRef<HTMLDivElement>()

        return (
          <Draggable
            axis={draggingFromAddButton || deleting ? 'none' : 'x'}
            handle={
              draggingFromAddButton || deleting
                ? 'none'
                : index === props.handlesXpercentages.length - 1
                ? '.handleRight'
                : index === 0
                ? '.handleLeft'
                : '.handleMiddle'
            }
            onStart={(e: any) => {
              e.preventDefault()
              setDraggingLine(true)
              if (
                (e.target.className &&
                  e.target.className.length > 0 &&
                  !e.target.className.includes('addSelection')) ||
                typeof e.target.className !== 'string'
              ) {
                handleDragLine(e, index)
              }
            }}
            onDrag={(e: any) => {
              handleDragLine(e, index)
            }}
            onStop={(e: any) => {
              setDraggingLine(false)
              if (
                e.target.className &&
                typeof e.target.className === 'string' &&
                !e.target.className.includes('deleteButton')
              ) {
                snapToclosestRealProbability(index)
              }
            }}
            nodeRef={handleRef}
            position={{
              x: props.handlesXpercentages[index] * props.fullGraphWidth - 20,
              y: 0,
            }}
            bounds={{
              left:
                props.handlesXpercentages[index - 1] !== undefined
                  ? (props.handlesXpercentages[index - 1] + allowedDistance) *
                      props.fullGraphWidth -
                    20
                  : -20,
              right:
                props.handlesXpercentages[index + 1] !== undefined
                  ? (props.handlesXpercentages[index + 1] - allowedDistance) *
                      props.fullGraphWidth -
                    20
                  : props.fullGraphWidth - 20,
            }}
            key={`handle${index}`}
          >
            <div className={'handleContainer'}>
              <HandleComponent
                referenceNode={handleRef}
                className={
                  index === props.handlesXpercentages.length - 1
                    ? 'handleRight'
                    : index === 0
                    ? 'handleLeft'
                    : 'handleMiddle'
                }
                height={selectionAreaHeight}
                handlesXpercentages={props.handlesXpercentages}
                limitAmount={handleAmounts[index]}
                statsticalModel={props.statisticalModel}
                handleIndex={index}
                tree={props.tree}
                negativeGraph={props.negativeGraph}
                showAddSelection={showAddSelection}
                setShowAddSelection={setShowAddSelection}
                setDraggingFromAddButton={setDraggingFromAddButton}
                draggingFromAddButton={draggingFromAddButton}
                addNewSelectionArea={addNewSelectionArea}
                showDeleteButton={showDeleteButton}
                setShowDeleteButton={setShowDeleteButton}
                deleteHandleLine={deleteHandleLine}
                topLeftOffset={topLeftOffset}
                doubleLimitValues={props.doubleLimitValues}
                hoverArea={hoverArea}
                setHoverArea={setHoverArea}
                pdfPreview={props.pdfPreview}
              />
            </div>
          </Draggable>
        )
      })}
    </div>
  )
}
