import { AmountSignum, ClaimType } from '../models/enums'
import { OutcomeBreakdown, ResultsObject } from '../models/generalTypes'
import { IndependentClaim } from '../models/independentClaim'
import { ScenarioSnapshot } from '../models/scenarioSnapshot'
import { TreeClaim } from '../models/treeModels/treeClaim'
import { EventId } from '../models/treeModels/treeTypes'

export function initializeDataForTable(
  outcomesList: any[],
  currentSnapshot: ScenarioSnapshot,
  maxmin?: boolean,
) {
  /* Creates an array with all the results that it is used for sorting
    Indexes of array:
    0 - id
    1 - Description : it can be 0,1,2 or 3
    2 - probability_of_outcome
    3 - amount_received
    4 - interest_received
    5 - own_legal_fees
    6 - ownlegal_fees_covered_by_other_legal_fees > 0
    7 - ownlegal_fees_covered_by_other_legal_fees < 0
    8 - legal_fees_covered_by_insurance
    9 - amount_received_out_of_court
    10 - Unsuccessful enforcement
    11 - financial_outcome
    12 - probability_value
    13 - percentage_of_legal_fees
    14 - percentage_of_legal_fees_to_pay
    15 - percentage_of_total_claim
    16 - breakdown
    17 - name
    */
  const scenarioID = ['Best Scenario', 'Worst Scenario']

  var tempData: (string | number | OutcomeBreakdown[])[][] = []
  if (outcomesList.length > 0) {
    outcomesList.map((item: any, index: number) => {
      if (maxmin) {
        tempData.push([scenarioID[index]])
      } else {
        tempData.push([index])
      }
      //conditional for finding Description
      if (item.trial_count === 1 && !item.has_bankruptcy) {
        tempData[index].push(0)
      } else if (item.trial_count === 2 && !item.has_bankruptcy) {
        tempData[index].push(1)
      } else if (item.trial_count === 1 && item.has_bankruptcy) {
        tempData[index].push(2)
      } else if (item.trial_count === 2 && item.has_bankruptcy) {
        tempData[index].push(3)
      }
      tempData[index].push(item.probability_of_outcome)
      tempData[index].push(item.amount_received)
      tempData[index].push(item.interest_received)
      tempData[index].push(
        item.trial_count === 1
          ? -currentSnapshot.legalCosts.clientLegalCosts?.firstProceedings
              .estimatedCosts! && currentSnapshot.legalCosts.included
            ? -currentSnapshot.legalCosts.clientLegalCosts?.firstProceedings
                .estimatedCosts!
            : 0
          : currentSnapshot.legalCosts.clientLegalCosts?.firstProceedings
              .estimatedCosts! &&
            currentSnapshot.legalCosts.clientLegalCosts?.additionalProceedings
              ?.estimatedCosts! &&
            currentSnapshot.legalCosts.included
          ? -(
              currentSnapshot.legalCosts.clientLegalCosts?.firstProceedings
                .estimatedCosts! +
              currentSnapshot.legalCosts.clientLegalCosts?.additionalProceedings
                ?.estimatedCosts!
            )
          : 0,
      )
      if (item.legal_fees_covered_by_other > 0) {
        tempData[index].push(item.legal_fees_covered_by_other)
      } else {
        tempData[index].push(0)
      }
      if (item.legal_fees_covered_by_other < 0) {
        tempData[index].push(item.legal_fees_covered_by_other)
      } else {
        tempData[index].push(0)
      }
      tempData[index].push(item.legal_fees_covered_by_insurance)
      tempData[index].push(item.amount_received_out_of_court)
      tempData[index].push(item.enforcement_loss)
      tempData[index].push(item.financial_outcome)
      tempData[index].push(item.probability_value)
      tempData[index].push(item.percentage_of_legal_fees)
      tempData[index].push(item.percentage_of_legal_fees_to_pay)
      tempData[index].push(item.percentage_of_total_claim)
      tempData[index].push(item.breakdown)
      tempData[index].push(item.name)

      return true
    })
  }
  return tempData
}

export function showLegalFees(dataForTable: any) {
  if (
    dataForTable.findIndex((item: any) => {
      if (item[5] !== 0 || item[6] !== 0 || item[7] !== 0 || item[8] !== 0) {
        return true
      }
      return false
    }) >= 0
  ) {
    return true
  }
  return false
}
export function showInterest(dataForTable: any) {
  if (
    dataForTable.findIndex((item: any) => {
      if (item[4] !== 0) {
        return true
      }
      return false
    }) >= 0
  ) {
    return true
  }
  return false
}
export function showInsurance(dataForTable: any) {
  if (
    dataForTable.findIndex((item: any) => {
      if (item[8] !== 0) {
        return true
      }
      return false
    }) >= 0
  ) {
    return true
  }
  return false
}
export function showOutOfCourt(dataForTable: any) {
  if (
    dataForTable.findIndex((item: any) => {
      if (item[9] !== 0) {
        return true
      }
      return false
    }) >= 0
  ) {
    return true
  }
  return false
}
export function showEnforcement(dataForTable: any) {
  if (
    dataForTable.findIndex((item: any) => {
      if (item[10] !== 0) {
        return true
      }
      return false
    }) >= 0
  ) {
    return true
  }
  return false
}

export function calculateHeight(dataForTable: any) {
  let heigth = 285

  if (showInterest(dataForTable)) {
    heigth += 40
  }
  if (showLegalFees(dataForTable)) {
    heigth += 110
    if (showInsurance(dataForTable)) {
      heigth += 40
    }
  }
  if (showOutOfCourt(dataForTable)) {
    heigth += 40
  }
  if (showEnforcement(dataForTable)) {
    heigth += 40
  }
  return heigth
}

export function treeHasOnlyAmount(data: any) {
  if (
    data.findIndex((item: any) => {
      if (item[2] !== 0 || item[3] !== 0) {
        return true
      }
      return false
    }) >= 0
  ) {
    return false
  }
  return true
}

export function treeHasOutOfCourt(data: any) {
  if (
    data.findIndex((item: any) => {
      if (item[3] !== 0) {
        return true
      }
      return false
    }) >= 0
  ) {
    return true
  }
  return false
}
export function checkIfTreeHasInterest(
  data: any,
  currentSnapshot: ScenarioSnapshot,
) {
  if (data === undefined) {
    return false
  }
  const hasFirstTrial = currentSnapshot.firstTrialDate !== undefined

  const hasSecondTrial =
    currentSnapshot.hasSecondTrial &&
    currentSnapshot.secondTrialDate !== undefined

  if (
    hasFirstTrial &&
    hasSecondTrial &&
    currentSnapshot.secondTrialDate! <= currentSnapshot.firstTrialDate!
  ) {
    return false
  }

  if (
    data.findIndex((item: any) => {
      if (item[2] !== 0 && item[2] !== undefined) {
        return true
      }
      return false
    }) >= 0 &&
    hasFirstTrial
  ) {
    return true
  }
  return false
}

export function treeTableWidth(data: any, currentSnapshot: ScenarioSnapshot) {
  let width = 305
  if (treeHasOutOfCourt(data)) {
    width += 180
  }
  if (checkIfTreeHasInterest(data, currentSnapshot)) {
    width += 110
  }
  if (treeHasOnlyAmount(data) === false) {
    width += 120
  }
  return width
}

export function claimOrEventHasInterest(
  claim: IndependentClaim | TreeClaim,
  breakdown: OutcomeBreakdown,
) {
  if (claim.type === 'tree') {
    return (claim as TreeClaim).treeDetails.events[breakdown.eventId]
      .eventDetails.hasInterest
  } else {
    return (claim as IndependentClaim).isInterest
  }
}

export function findClaimScenarioOrEventProbability(
  claim: IndependentClaim | TreeClaim,
  breakdown: OutcomeBreakdown,
) {
  if (claim.type === 'tree') {
    return (claim as TreeClaim).treeDetails.events[breakdown.eventId]
      .eventDetails.probability
  } else {
    return (claim as IndependentClaim).quantumScenarios[breakdown.scenarioIndex]
      .probability
  }
}
export function findClaimScenarioOrEventAwardedAmount(
  claim: IndependentClaim | TreeClaim,
  breakdown: OutcomeBreakdown,
) {
  if (claim.type === 'tree') {
    return (claim as TreeClaim).treeDetails.events[breakdown.eventId]
      .eventDetails.awardedAmount !== undefined
      ? (claim as TreeClaim).treeDetails.events[breakdown.eventId].eventDetails
          .awardedAmount!
      : 0
  } else {
    return (claim as IndependentClaim).quantumScenarios[breakdown.scenarioIndex]
      .amount !== undefined
      ? (claim as IndependentClaim).quantumScenarios[breakdown.scenarioIndex]
          .amount!
      : 0
  }
}
export function findClaimScenarioOrEventOutOfCourtAmount(
  claim: IndependentClaim | TreeClaim,
  breakdown: OutcomeBreakdown,
): number {
  if (claim.type === 'tree') {
    return (claim as TreeClaim).treeDetails.events[breakdown.eventId]
      .eventDetails.outOfCourtAmount !== undefined
      ? (claim as TreeClaim).treeDetails.events[breakdown.eventId].eventDetails
          .outOfCourtAmount!
      : 0
  } else {
    return (claim as IndependentClaim).quantumScenarios[breakdown.scenarioIndex]
      .outOfCourtAmount !== undefined
      ? (claim as IndependentClaim).quantumScenarios[breakdown.scenarioIndex]
          .outOfCourtAmount!
      : 0
  }
}

export function findClaimOrEventInterestRate(
  claim: IndependentClaim | TreeClaim,
  breakdown: OutcomeBreakdown,
) {
  if (claim.type === 'tree') {
    return (
      (claim as TreeClaim).treeDetails.events[breakdown.eventId].eventDetails
        .interestRate * 100
    )
  } else {
    return (claim as IndependentClaim).interestRate!
  }
}

export function findClaimOrEventInterestDate(
  claim: IndependentClaim | TreeClaim,
  breakdown: OutcomeBreakdown,
) {
  if (claim.type === 'tree') {
    return (claim as TreeClaim).treeDetails.events[breakdown.eventId]
      .eventDetails.interestDate!
  } else {
    return (claim as IndependentClaim).interestDate!
  }
}

export function calculateClaimScenarioOrEventInterest(
  claim: IndependentClaim | TreeClaim,
  breakdown: OutcomeBreakdown,
  trialDate: string,
) {
  let interestDate = findClaimOrEventInterestDate(claim, breakdown)
  const date1 = new Date(trialDate)
  const date2 = new Date(interestDate)

  const timeDiff = new Date(date1).getTime() - new Date(date2).getTime()
  const daysDiff = Math.round(timeDiff / (1000 * 3600 * 24))

  const interest = isNaN(
    (findClaimScenarioOrEventAwardedAmount(claim, breakdown) *
      daysDiff *
      findClaimOrEventInterestRate(claim, breakdown)) /
      100 /
      365,
  )
    ? 0
    : (findClaimScenarioOrEventAwardedAmount(claim, breakdown) *
        daysDiff *
        findClaimOrEventInterestRate(claim, breakdown)) /
      100 /
      365

  return interest
}

export function atLeastOneClaimHasInterest(snapshot: ScenarioSnapshot) {
  for (let claim of snapshot.claims) {
    if (claim.type === ClaimType.tree) {
      let treeEvents = (claim as TreeClaim).treeDetails.events
      for (let event of Object.keys(treeEvents)) {
        if (
          treeEvents[event as EventId].eventDetails.hasInterest &&
          treeEvents[event as EventId].eventDetails.interestDate !==
            undefined &&
          treeEvents[event as EventId].eventDetails.interestRate > 0
        ) {
          return true
        }
      }
    } else {
      if (
        (claim as IndependentClaim).isInterest &&
        (claim as IndependentClaim).interestDate !== undefined &&
        (claim as IndependentClaim).interestRate! > 0
      ) {
        return true
      }
    }
  }
  return false
}

export function atLeastOneTreeHasReducedAmounts(snapshot: ScenarioSnapshot) {
  let trees = snapshot.claims.filter((c) => c.type === ClaimType.tree)

  if (trees.length === 0) {
    return false
  }

  let treeEvents = trees
    .map((c) => (c as TreeClaim).treeDetails.events)
    .reduce((a, b) => ({ ...a, ...b }))

  for (let event of Object.values(treeEvents)) {
    if (event.eventDetails.reducedAwardedAmount !== 0) {
      return true
    }
  }

  return false
}

export function atLeastOneTreeHasExternalValue(snapshot: ScenarioSnapshot) {
  let trees = snapshot.claims.filter((c) => c.type === ClaimType.tree)

  if (trees.length === 0) {
    return false
  }

  let treeEvents = trees
    .map((c) => (c as TreeClaim).treeDetails.events)
    .reduce((a, b) => ({ ...a, ...b }))

  for (let event of Object.values(treeEvents)) {
    if (event.eventDetails.outOfCourtAmountSignum !== AmountSignum.off) {
      return true
    }
  }

  return false
}

export function calculateGetPayArray(resultsFromBackend: ResultsObject) {
  const maxAmount = resultsFromBackend.minmax.max.financial_outcome
  const minAmount = resultsFromBackend.minmax.min.financial_outcome
  const numberOfSegments = 5

  let limitsArray = Array.from(
    { length: numberOfSegments + 2 },
    (_, i) =>
      maxAmount - (i * (maxAmount - minAmount)) / (numberOfSegments + 1),
  )

  // Exclude the first (maxAmount) and last (minAmount) points
  limitsArray = limitsArray.slice(1, -1)

  // Ensure 0 is included and still exclude the min and max
  if (!limitsArray.includes(0)) {
    limitsArray.push(0)
  }

  let getPayArray: [number, number][] = []
  const financialOutcomeSortedArray: number[] =
    resultsFromBackend.result.graph.map((item: number[]) => item[0])

  for (const limit of limitsArray) {
    if (limit < 0) {
      const payArray: number[] = financialOutcomeSortedArray
        .map((value, index) => (value < limit ? index : -1))
        .filter((index) => index !== -1)

      const index: number =
        payArray.length > 0 && payArray[0] - 1 >= 0 ? payArray[0] - 1 : -1
      const limitProbability: number =
        index > -1 ? 1 - resultsFromBackend.result.graph[index][1] : 0

      if (limitProbability !== 0) {
        getPayArray.push([limit, limitProbability])
      }
    } else if (limit === 0) {
      // Probability of paying more than 0
      const payArray: number[] = financialOutcomeSortedArray
        .map((value, index) => (value < limit ? index : -1))
        .filter((index) => index !== -1)

      const index: number =
        payArray.length > 0 && payArray[0] - 1 >= 0 ? payArray[0] - 1 : -1

      let limitProbability: number =
        index > -1
          ? 1 - resultsFromBackend.result.graph[index][1]
          : payArray[0] === 0
          ? 1
          : 0
      if (limitProbability !== 0) {
        getPayArray.push([-0.00001234567, limitProbability])
      }
      // Probability of winning 0 or more
      const getArray: number[] = financialOutcomeSortedArray
        .map((value, index) => (value >= limit ? index : -1))
        .filter((index) => index !== -1)

      const winIndex: number =
        getArray.length > 0 && getArray[getArray.length - 1] >= 0
          ? getArray[getArray.length - 1]
          : -1
      limitProbability =
        winIndex > -1 ? resultsFromBackend.result.graph[winIndex][1] : 0
      if (limitProbability !== 0) {
        getPayArray.push([0, limitProbability])
      }
    } else if (limit > 0) {
      const getArray: number[] = financialOutcomeSortedArray
        .map((value, index) => (value > limit ? index : -1))
        .filter((index) => index !== -1)

      const index: number =
        getArray.length > 0 && getArray[getArray.length - 1] >= 0
          ? getArray[getArray.length - 1]
          : -1
      const limitProbability: number =
        index > -1 ? resultsFromBackend.result.graph[index][1] : 0
      if (limitProbability !== 0) {
        getPayArray.push([limit, limitProbability])
      }
    }
  }
  getPayArray.sort((a, b) => b[0] - a[0])

  const indexesToRemove: number[] = []
  for (let i = 0; i < getPayArray.length - 1; i++) {
    if (getPayArray[i][1] === getPayArray[i + 1][1]) {
      if (
        getPayArray[i][0] !== 0 &&
        getPayArray[i][0] !== -0.00001234567 &&
        Math.abs(getPayArray[i][0]) < Math.abs(getPayArray[i + 1][0])
      ) {
        indexesToRemove.push(i)
      }
      if (
        getPayArray[i + 1][0] !== 0 &&
        getPayArray[i + 1][0] !== -0.00001234567 &&
        Math.abs(getPayArray[i + 1][0]) < Math.abs(getPayArray[i][0])
      ) {
        indexesToRemove.push(i + 1)
      }
    }
  }

  getPayArray = getPayArray.filter(
    (x, index) => !indexesToRemove.includes(index),
  )

  return getPayArray
}

export function getDistanceNumberForSelectionGraph(
  requestedNumber: 'topLeftOffset',
  condition:
    | 'onlyAmount'
    | 'onlyInterest'
    | 'onlyExternal'
    | 'externalInterest'
    | 'results'
    | 'report',
) {
  if (requestedNumber === 'topLeftOffset') {
    if (condition === 'onlyAmount') {
      return [69, 69]
    } else if (condition === 'onlyInterest') {
      return [69, 68]
    } else if (condition === 'onlyExternal') {
      return [76, 78]
    } else if (condition === 'externalInterest') {
      return [88, 95]
    } else if (condition === 'results') {
      return [60, 120]
    } else if (condition === 'report') {
      return [20, 121]
    }
  }
  return [60, 120]
}

export function getHighestValue(array: number[][], value: number): number {
  return Math.max(...array.filter((el) => el[0] === value).map((el) => el[1]))
}

export function getLowestValue(array: number[][], value: number): number {
  return Math.min(...array.filter((el) => el[0] === value).map((el) => el[1]))
}

export function resultsAreNotZero(data: number[][]): boolean {
  for (let result of data) {
    if (result[0] !== 0) {
      return true
    }
  }
  return false
}
