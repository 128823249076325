import React, { useEffect, useState } from 'react'

import SelectionGraph from './graphComponents/selectionGraph/SelectionGraph'
import DistributionGraph from './graphComponents/distributionGraph/DistributionGraph'
import { ResultsObject } from '../../../../../../models/generalTypes'
import { ScenarioSnapshot } from '../../../../../../models/scenarioSnapshot'
import { getText } from '../../../../../../services/textFunctions'
import { ActionMessage, VideoPostName } from '../../../../../../models/enums'
// import VideoInfoButton from '../../../../../../Components/InfoHover/videoInfoButton/VideoInfoButton'
import { useRecoilValue } from 'recoil'
import { userState } from '../../../../../../states/UserState'
import { fullscreenActionMessageState } from '../../../../../../states/FullScreenActionMessageState'
import DownloadImage from '../../../../../../Components/downloadImages/DownloadImage'
import { scenarioIdentityState } from '../../../../../../states/ScenarioIdentityState'
import { stringAndRoundTo2Decimals } from '../../../../../../Modules/DisputeModules/AppFunctions'
import { scenarioSnapshotState } from '../../../../../../states/ScenarioSnapshotState'
import GetPayGraph from './graphComponents/getPayGraph/GetPayGraph'

type Props = {
  resultsFromBackend: ResultsObject
  typeOfGraph: 'distribution' | 'selection' | 'getPay'
  currentSnapshot: ScenarioSnapshot
  widthOfDiv: number
  heightOfDiv: number
  setVideoPostName: (param: VideoPostName | undefined) => void
  setShowAWADetails: (showAWADetails: boolean) => void
  resultsViewParty: 'client' | 'opposing'
  loadingResultsSettings?: boolean
}

export default function Graph(props: Props) {
  //const { getValues } = useFormContext()
  /* 
  
  tabs:
  0 - DISTRIBUTION GRAPH
  1 - SELECTION GRAPH
  2 - GET PAY GRAPH
  
  */
  const user = useRecoilValue(userState)
  const scenarioSnapshot = useRecoilValue(scenarioSnapshotState)
  const fullscreenActionMessage = useRecoilValue(fullscreenActionMessageState)
  const scenarioIdentity = useRecoilValue(scenarioIdentityState)
  const [saveGraphImageName, setSaveGraphImageName] = useState('')

  const partiesFormat = [
    scenarioSnapshot.currentSnapshot.partyFormatOwn,
    scenarioSnapshot.currentSnapshot.partyFormatOther,
  ]

  useEffect(() => {
    let tempSaveGraphImageName = `${scenarioIdentity.caseName} - ${
      scenarioIdentity.scenarioName
    } - ${
      props.typeOfGraph === 'selection'
        ? getText('title-265', user.settings)
            .toLowerCase()
            .split(' ')
            .map((word) => {
              return word[0].toUpperCase() + word.substring(1)
            })
            .join(' ')
        : props.typeOfGraph === 'distribution'
        ? getText('title-264', user.settings)
            .toLowerCase()
            .split(' ')
            .map((word) => {
              return word[0].toUpperCase() + word.substring(1)
            })
            .join(' ')
        : getText('button-160', user.settings)
    } - ${
      props.resultsViewParty === 'client'
        ? getText('title-260', user.settings, partiesFormat)
        : getText('title-261', user.settings, partiesFormat)
    }`
    setSaveGraphImageName(tempSaveGraphImageName)
    // eslint-disable-next-line
  }, [scenarioIdentity, props.typeOfGraph])

  function getGraphTitle(typeOfGraph: 'distribution' | 'selection' | 'getPay') {
    if (typeOfGraph === 'distribution') {
      //newText
      //data-textattribute = 'title-264'
      return getText('title-264', user.settings)
    } else if (typeOfGraph === 'selection') {
      //newText
      //data-textattribute = 'title-265'
      return getText('title-265', user.settings)
    } else if (typeOfGraph === 'getPay') {
      return getText('title-263', user.settings)
    }
  }
  function getGraphSubTitle(
    typeOfGraph: 'distribution' | 'selection' | 'getPay',
  ) {
    if (typeOfGraph === 'selection') {
      //data-textattribute = 'description-255'
      return getText('description-255', user.settings)
    } else if (typeOfGraph === 'distribution') {
      //data-textattribute = 'description-256'
      return getText('description-256', user.settings)
    } else if (typeOfGraph === 'getPay') {
      return getText('description-249', user.settings)
    }
  }

  return (
    <div
      className="tool-component results-tool-component"
      id={`graphComp-${props.typeOfGraph}`}
      style={
        fullscreenActionMessage === ActionMessage.downloadingImage
          ? { marginTop: 0, minHeight: 760 }
          : props.typeOfGraph === 'getPay'
          ? { minHeight: 400 }
          : props.typeOfGraph === 'distribution'
          ? { minHeight: 500 }
          : { minHeight: 700 }
      }
    >
      <div
        className="downloadImageComponentContainer"
        style={
          fullscreenActionMessage === ActionMessage.downloadingImage
            ? { visibility: 'hidden' }
            : undefined
        }
      >
        <DownloadImage
          id={
            props.typeOfGraph === 'selection'
              ? 'selectionGraph'
              : props.typeOfGraph === 'distribution'
              ? 'distributionGraph'
              : 'getPayGraph'
          }
          tooltipText={
            props.typeOfGraph === 'selection'
              ? getText('tooltip-32', user.settings)
              : props.typeOfGraph === 'distribution'
              ? getText('tooltip-33', user.settings)
              : getText('tooltip-67', user.settings)
          }
          tooltipTextAttribute={
            props.typeOfGraph === 'selection'
              ? 'tooltip-32'
              : props.typeOfGraph === 'distribution'
              ? 'tooltip-33'
              : 'tooltip-00'
          }
          imageElementId={`graphComp-${props.typeOfGraph}`}
          saveName={saveGraphImageName}
          downloadImageType="results"
        />
      </div>
      <div
        className="tool-component-title"
        id={`graphTitle-${props.typeOfGraph}`}
        data-textattribute="title-39"
      >
        {fullscreenActionMessage !== ActionMessage.downloadingImage ? (
          <>
            {getGraphTitle(props.typeOfGraph)}
            {/* <div className="videoInfoButtonComponentContainer">
              <VideoInfoButton
                textAttribute="tooltip-19"
                postName={VideoPostName.resultsGraphs}
                setVideoPostName={props.setVideoPostName}
              />
            </div> */}
          </>
        ) : (
          getGraphTitle(props.typeOfGraph)
        )}
      </div>
      <p className="graphSubtitle" id={`graphSubtitle-${props.typeOfGraph}`}>
        {getGraphSubTitle(props.typeOfGraph)}
      </p>
      <div className="graphContainer">
        <div
          className="tab-content"
          id="graphContainer"
          data-openreplay-obscured
        >
          {props.typeOfGraph === 'selection' ? (
            <SelectionGraph
              resultsFromBackend={props.resultsFromBackend}
              currentSnapshot={props.currentSnapshot}
              loadingResultsSettings={props.loadingResultsSettings}
              resultsViewParty={props.resultsViewParty}
            />
          ) : props.typeOfGraph === 'distribution' ? (
            <DistributionGraph
              resultsFromBackend={props.resultsFromBackend}
              currentSnapshot={props.currentSnapshot}
            />
          ) : props.typeOfGraph === 'getPay' ? (
            <GetPayGraph
              resultsFromBackend={props.resultsFromBackend}
              currentSnapshot={props.currentSnapshot}
            />
          ) : null}
        </div>
      </div>
      {props.resultsFromBackend &&
        props.resultsFromBackend.model_used === 'statistical' && (
          <p
            className={
              props.typeOfGraph === 'distribution'
                ? 'asteriskMessageContainer distribution'
                : 'asteriskMessageContainer'
            }
            id="graph-asteriskMessageContainer"
            data-textattribute={'description-176'}
            data-openreplay-obscured
          >
            {getText('description-176', user.settings)}{' '}
            {stringAndRoundTo2Decimals(
              props.resultsFromBackend.difference_from_value,
              user.settings,
            )}{' '}
            {scenarioSnapshot.currentSnapshot.currency}.{' '}
            <span
              className="asteriskMessageContainer-learnMoreSpan"
              id="asteriskMessageContainer-learnMoreSpan-graph"
              onClick={() => props.setShowAWADetails(true)}
              data-textattribute={'description-177'}
            >
              {getText('description-177', user.settings)}
            </span>
          </p>
        )}
    </div>
  )
}
