import { useRecoilValue } from 'recoil'
import { ResultsObject } from '../../../../../../../../models/generalTypes'
import { ReportWhitelabel } from '../../../../../../../../models/reportModels/reportGeneralTypes'
import { ScenarioSnapshot } from '../../../../../../../../models/scenarioSnapshot'
import { userState } from '../../../../../../../../states/UserState'
import { calculateGetPayArray } from '../../../../../../../../services/resultsFunctions'
import {
  GREEK_EASTER,
  INFANTRY_WEST,
} from '../../../../../../../../services/constants'
import { stringAndRoundBigNumbers } from '../../../../../../../../Modules/DisputeModules/AppFunctionsNew'
import { resultsSettingsState } from '../../../../../../../../states/ResultsSettingsState'
import { findDisputedAmount } from '../../../../../../../../services/disputedAmountFunctions'
import {
  FontLabels,
  ReportWhitelabelKeys,
} from '../../../../../../../../models/reportModels/reportEnums'
import { getStyleFromWhitelabel } from '../../../../../../../../services/reportFunctions'
import { getText } from '../../../../../../../../services/textFunctions'

type Props = {
  resultsFromBackend: ResultsObject
  currentSnapshot: ScenarioSnapshot
  pdfPreview?: boolean
  reportWhitelabel?: ReportWhitelabel
}
export default function GetPayGraph(props: Props) {
  const user = useRecoilValue(userState)
  const resultsSettings = useRecoilValue(resultsSettingsState)

  const getPayGraphArray = calculateGetPayArray(props.resultsFromBackend)

  return (
    <>
      {props.pdfPreview && (
        <div
          className="getPayGraphSubtitle"
          data-textattribute="description-249"
          style={getStyleFromWhitelabel(
            props.reportWhitelabel,
            ReportWhitelabelKeys.sectionDescription,
            FontLabels.regular,
            'noRoboto',
            false,
            false,
          )}
        >
          {getText('description-249', user.settings)}
        </div>
      )}

      <div className={'getPayChart'} data-openreplay-obscured>
        <div className="payGetGraphContainer" id="getPayGraphContainer">
          {getPayGraphArray.map((getPayGraphRow, index) => (
            <div
              className="getPayGraphRow"
              id={`getPayGraphRow-${index}`}
              key={`getPayGraphRow-${index}`}
            >
              <div
                className="getPayGraphRowLeftPart robotoNumbers"
                id={`getPayGraphRowLeftPart-${index}`}
                data-textattribute="description-250, description-251, description-252, description-253"
              >
                {getPayGraphRow[0] > 0 ? (
                  <p
                    style={getStyleFromWhitelabel(
                      props.reportWhitelabel,
                      ReportWhitelabelKeys.sectionDescription,
                      FontLabels.regular,
                      'noRoboto',
                      false,
                      false,
                    )}
                  >
                    {getText('description-250', user.settings)}{' '}
                    <span
                      style={getStyleFromWhitelabel(
                        props.reportWhitelabel,
                        ReportWhitelabelKeys.sectionDescription,
                        FontLabels.regular,
                        'roboto',
                        false,
                        false,
                      )}
                    >
                      {stringAndRoundBigNumbers(
                        getPayGraphRow[0],
                        user.settings,
                        resultsSettings.settings.roundedResults,
                        findDisputedAmount(props.currentSnapshot),
                      )}
                      {props.resultsFromBackend &&
                      props.resultsFromBackend.model_used === 'statistical'
                        ? '*'
                        : ''}
                    </span>{' '}
                    {props.currentSnapshot.currency}
                  </p>
                ) : getPayGraphRow[0] === 0 ? (
                  <p
                    style={getStyleFromWhitelabel(
                      props.reportWhitelabel,
                      ReportWhitelabelKeys.sectionDescription,
                      FontLabels.regular,
                      'noRoboto',
                      false,
                      false,
                    )}
                  >
                    {getText('description-251', user.settings)}{' '}
                    <span
                      style={getStyleFromWhitelabel(
                        props.reportWhitelabel,
                        ReportWhitelabelKeys.sectionDescription,
                        FontLabels.regular,
                        'roboto',
                        false,
                        false,
                      )}
                    >
                      0
                    </span>
                    {props.resultsFromBackend &&
                    props.resultsFromBackend.model_used === 'statistical'
                      ? '*'
                      : ''}{' '}
                    {props.currentSnapshot.currency}{' '}
                    {getText('description-252', user.settings)}
                  </p>
                ) : getPayGraphRow[0] === -0.00001234567 ? (
                  <p
                    style={getStyleFromWhitelabel(
                      props.reportWhitelabel,
                      ReportWhitelabelKeys.sectionDescription,
                      FontLabels.regular,
                      'noRoboto',
                      false,
                      false,
                    )}
                  >
                    {getText('description-253', user.settings)}{' '}
                    <span
                      style={getStyleFromWhitelabel(
                        props.reportWhitelabel,
                        ReportWhitelabelKeys.sectionDescription,
                        FontLabels.regular,
                        'roboto',
                        false,
                        false,
                      )}
                    >
                      0
                    </span>
                    {props.resultsFromBackend &&
                    props.resultsFromBackend.model_used === 'statistical'
                      ? '*'
                      : ''}{' '}
                    {props.currentSnapshot.currency}
                  </p>
                ) : (
                  <p
                    style={getStyleFromWhitelabel(
                      props.reportWhitelabel,
                      ReportWhitelabelKeys.sectionDescription,
                      FontLabels.regular,
                      'noRoboto',
                      false,
                      false,
                    )}
                  >
                    {getText('description-253', user.settings)}{' '}
                    <span
                      style={getStyleFromWhitelabel(
                        props.reportWhitelabel,
                        ReportWhitelabelKeys.sectionDescription,
                        FontLabels.regular,
                        'roboto',
                        false,
                        false,
                      )}
                    >
                      {stringAndRoundBigNumbers(
                        Math.abs(getPayGraphRow[0]),
                        user.settings,
                        resultsSettings.settings.roundedResults,
                        findDisputedAmount(props.currentSnapshot),
                      )}
                    </span>
                    {props.resultsFromBackend &&
                    props.resultsFromBackend.model_used === 'statistical'
                      ? '*'
                      : ''}{' '}
                    {props.currentSnapshot.currency}
                  </p>
                )}
              </div>
              <div className="getPayGraphRowRightPart">
                <div
                  className="getPayBar"
                  id={`getPayBar-${index}`}
                  style={{
                    width: getPayGraphRow[1] * 330,
                    backgroundColor:
                      getPayGraphRow[0] >= 0 ? INFANTRY_WEST : GREEK_EASTER,
                  }}
                ></div>
                <div
                  className="getPayBarPercentage robotoNumbers"
                  id={`getPayBarPercentage-${index}`}
                  style={getStyleFromWhitelabel(
                    props.reportWhitelabel,
                    ReportWhitelabelKeys.sectionDescription,
                    FontLabels.regular,
                    'roboto',
                    false,
                    false,
                  )}
                >
                  {stringAndRoundBigNumbers(
                    getPayGraphRow[1] * 100,
                    user.settings,
                    resultsSettings.settings.roundedResults,
                    findDisputedAmount(props.currentSnapshot),
                    false,
                    true,
                  )}
                  %
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}
